<script setup lang="ts">
const props = defineProps({
	modelValue: {
		type: Boolean,
		default: null
	},
	title: {
		type: String,
		default: undefined
	},
	fullscreen: {
		type: Boolean,
		default: false
	},
	center: {
		type: Boolean,
		default: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const onClose = () => {
	localValue.value = false
}
</script>

<template>
	<UModal
		v-model="localValue"
		:fullscreen="fullscreen"
		:ui="{
			base: 'relative overflow-y-auto',
			container: 'flex sm:items-center justify-center text-center' + (fullscreen ? ' items-stretch' : 'items-end'),
			fullscreen: 'w-screen h-[100svh] flex-1 basis-1',
			background: 'bg-stone-50',
			shadow: 'shadow-xs'
		}"
	>
		<div
			class="relative z-0"
			:class="{ 'my-auto': center }"
		>
			<slot />
		</div>
		<UButton
			variant="link"
			color="white"
			size="xl"
			icon="i-heroicons-x-mark-16-solid"
			class="md:hidden absolute top-[18px] md:top-[15px] right-[16px] w-8 h-8 md:w-10 md:h-10 !p-0 focus:ring-0 focus-visible:ring-0 z-100"
			:ui="{
				icon: {
					size: {
						xl: 'w-8 h-8 md:w-10 md:h-10'
					}
				}
			}"
			@click="onClose"
		/>
	</UModal>
</template>
